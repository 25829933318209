import React from "react"; 
import "../Back/back.css"


function Back({image, title,}) {
    return (
        <div className="baniere-components">
           <div className="baniere-img">
             <img src={image} alt="Kasa"/>
           </div>
           <div className="baniere-text">
            <h1><span>{title}</span></h1>
           </div>
        </div>)
}

export default Back