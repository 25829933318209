import Form from "../../components/Form/form"
import "./contact.css"
import Back from "../../components/Back/back";
import back from "../../assets/back.png"
import Laulie from "../../assets/Laulie.png"


function Contact (){
    return(
        <div className="contact-conteneur"> 
                <Back title="CONTACT" image={back} />
            <div className="contact-bloc">
                 <Form /> 
            <div className="img-bloc"><img src={Laulie}alt="site internet tourcoing" /></div>
            </div>
        </div>
)
}
export default Contact