import "../Home/home.css"
import Presentation from "../../components/Presentation/presentation"
import Diplome from "../../components/Diplomes/diplomes"
function Home (){
    return(
       
        <div className="home">
         <Presentation />
         <Diplome />

        </div>
    )
}
export default Home