import "../Portfolios/portfolios.css";
import portfolio from "../../data/portfolio.json"
import Back from "../../components/Back/back";
import back from "../../assets/back.png"
import Rate from "../Rating/rating";

function Portfolio (){
return(<div className="portfolio">
    <Back title="PORTFOLIO" image={back} />
    <section className="portfolio-elmt">
        { portfolio.map((product)=>{
        return(
        <div key={product.id}>
            <a href={product.liens} className="portfolio-contenant">
                <div className="portfolio-titre">{product.nom}</div>
                <div className="portfolio-img">
                    <img src={product.image} alt="dev web"></img>
                </div>
                <div className="portfolio-description">{product.description}</div>
                <div className="protfolio-note"> <Rate score={product.rating}/></div>
            </a> 
            
        </div>
           
        )
        })}
    </section>
</div>
)


}
export default Portfolio