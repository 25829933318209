

function Mentions (){
    return(
        <div className="mentions-contenant">
            <div className="mentions-presentation">
                <div className="mentions-presentation-title">
                    <h2>Créateur du site et informations sur la société</h2>
                </div>
                <div className="mentions-presentation-text">
                    <p>Laulie Web, 10 quai du havre 59200 Tourcoing Siret:98766314300012 Hébergeur : IONOS</p>
                </div>
            </div>
            <div className="mentions-propri">
                <div className="mentions-propri-title">
                    <h2>Propriété intellectuelle </h2>
                </div>
                <div className="mentions-propri-text">
                    
                </div>
            </div>
        </div>
    )
}
export default Mentions