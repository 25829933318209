import "./nav.css"
import { useState } from "react"
import Laulie from "../../assets/Laulie.png"
import { Link } from "react-router-dom"

function Nav (){
    const [showLinks, setShowLinks] = useState(false)

    const handleShowLinks = ()=>{
        setShowLinks(!showLinks)
    }
    return(
            <nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"}`}>
                   <Link to="/" className="navbar__logo1"><img src={Laulie} alt="laulie web"></img></Link>
                <ul className="navbar__links">
                    <Link to="/" className="navbar__logo"><img src={Laulie} alt="laulie web"></img></Link>
                    <li className="nav__items1">
                    <Link to="/" className="navbar__link"onClick={handleShowLinks}>Accueil</Link>
                    </li>
                    <li className="nav__items2">
                    <Link to="/Portfolio" className="navbar__link"onClick={handleShowLinks}>Portfolio</Link>
                    </li>   
                    <li className="nav__items5">
                    <Link to="/Tarifs" className="navbar__link"onClick={handleShowLinks}>Tarifs</Link>
                    </li>
                    <li className="nav__items6">
                    <Link to="/Contact" className="navbar__link"onClick={handleShowLinks}>Contact</Link>
                    </li>
                </ul>
                <button className="navbar__burger"onClick={handleShowLinks}>
                    <span className="burger-bar"></span>
                </button>
            </nav>
    )
}
export default Nav