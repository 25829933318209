import Tarifs from "../../components/Tarifs/tarifs";
import "../Tarifs/tarifs.css"
import Back from "../../components/Back/back";
import back from "../../assets/back.png"

function Tarif (){
    return(
        <div className="tarif-background">
                <Back title="TARIFS" image={back}/>
            <Tarifs />
        </div>
       
    )
}
export default Tarif